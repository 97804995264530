.info {
    border: 1px solid;
    margin: 20px;
    padding: 15px 10px 15px 50px;
    border-radius: 30px;
    max-width: 1080px;
    background-repeat: no-repeat;
    background-position: 10px center;
}
.info {
    color: #00529B;
    background-color: #BDE5F8;
    background-image: url('https://i.imgur.com/ilgqWuX.png');
}