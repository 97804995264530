/* .App {
    text-align: left;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    max-height: 100vh;
    color: #000133;
    text-align: left;
    display: flex;
    height: 100vh;
  } */


  .App {
    display: flex;
    height: 100vh;
    text-align: left;
    flex-direction: column;
  }

  .header,
  .footer {
    background: #00022e;
    height: 50px;
    color: #fc86aa;
  }

  .container {
    display: flex;
    flex: 1;
  }

  .drawer {
    /* background: #d8dcd6; rgb(61,108,48)*/
    width: 240px;
    background-color:white ;
    box-shadow: 0 0 6px hsl(210 14% 90%);
    margin-right:0px;

  }

  .main {
    flex: 1;
    background: rgba(248, 249, 252, 1);
    overflow-y: hidden;
  }

  .select-product {
    position: absolute;
    top: 95px;
    height: 40px;
    width: 140px;
    font-size: 16px;
  }




