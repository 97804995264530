.custom-select {
  font-size: 16px;
  border-radius: 4px;
  border-color: rgba(208, 208, 208, 1);
  min-width: 175px;
  background-color: white;
}

.custom-menu-item {
  font-size: 16px;
  padding: 8px;
  margin: 8px;
}