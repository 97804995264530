.feedback {
    color: white;
    padding: 10px 20px;
    border-radius: 30px;
    margin:10px;
  }
  
  .mybutton {
    position: fixed;
    bottom: -4px;
    right: 10px;
    margin: 10px;
    padding: 10px;
  }

