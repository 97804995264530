
.btn {
  border: 2px solid rgb(21, 27, 128);
  background-color: white;
  color: rgb(21, 27, 128);
  padding: 5px 5px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 10px;
}
.btn:hover {
  border: 1px solid rgb(21, 27, 128);
  background-color: white;
  color: rgb(21, 27, 128);
  padding: 5px 5px;
  font-size: 16px;
  cursor: pointer;
}


.success {
  border-color: green;
  color: green;
}




  .link{
    display: flex;
    align-items: cover;
    cursor: pointer;
    border-radius: 30px;
    margin:5px 0px 5px 0px;
  }
  .link:hover{
    background-color: #F0F8FF;
    color: rgb(21, 27, 128);
    font-weight: 500;
    transition: background-color 100ms ease-out;
  }


/* SIDEBAR */

.sidebar {
  min-width: 240px;
  background-color: white;
  color:black ;
  box-shadow: 0 0 6px hsl(210 14% 90%);
  /* margin:0px 1px 0px 0px; */
}



.sidebar-closed {
  min-width: 80px;
  background-color: white;
  color:black ;
  transition: 0.4s ease-in;
}

.innerDiv {
  list-style-type: none;
  padding: 0;
  margin: 40px 10px 20px 10px;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  height: 70vh;
}

.innerDiv::-webkit-scrollbar {
  display: none;
}

.sidebar__listItem {
  display: flex;
  padding: 0px;
}


.sidebar__listItem span {
  white-space: nowrap;
  margin: 10px 0px 20px 15px;
}

.sidebar__icon {
  margin: 10px 0px 10px 15px;
  height:26px;
  width:26px;
}



/* hjdkfjeshflehsfhlwrhfe */





